(function ($) {
	// var templatePath = "https://mmfreight.co.uk/site/wp-admin";
	$(document).on('click', '.store-type', function() {
		// e.preventDefault();
		var map_id = $(this).find('span.map-shortcode').text();
		var data = {
			'action' : 'change_map',
			'mapid'	 : map_id
		}
		console.log(map_id);
		// $.post(ajax_object.ajax_url, data, function(response) {
		// 	console.log(response);
		// });
		 $.ajax({
		    type: 'post',
		    url: ajax_object.ajax_url,
		    data: data,
		    success: function(data) { 
		        console.log('success')
		    },
		    error: function(e) {
                console.log('error');
            }
		});
	});
})(jQuery);